import { ref } from 'vue'
import { useShop } from '@/use/shop'
import { GetService, GetServiceCount } from '@/api/service'
import { get, concat } from 'lodash'
export const useService = () => {
  const { shopId } = useShop()
  const serviceList = ref([])
  const getAllService = async () => {
    try {
      let max = await getServiceCategoryCount()
      max = max.data.count
      const options = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
      }
      const res = await GetService(options)
      // console.log(res)
      serviceList.value = res
      while (get(serviceList, 'value.length') < max) {
        options.start += options.limit
        const res = await GetService(options)
        serviceList.value = concat(serviceList.value, res)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getServiceCategoryCount = async () => {
    try {
      return await GetServiceCount({ shopId: shopId.value })
    } catch (error) {
      console.log(error)
    }
  }
  return { getAllService, serviceList }
}
